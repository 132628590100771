import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { BordersAdditive, BordersColor, BordersRadius, BordersSubtractive } from "../../examples/utilities/Borders";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 id="border" className="h1 font-weight-bold">
  Border
  <a className="header-link text-light text-decoration-none font-weight-normal" href="#border" aria-label="anchor"></a>
    </h2>
    <p>
  Use border utilities to add or remove an element’s borders. Choose from all
  borders or one at a time.
    </p>
    <CodeBlock title="Additive" code={BordersAdditive} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Subtractive" code={BordersSubtractive} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Border Color" code={BordersColor} mdxType="CodeBlock">
  Change the border color using utilities built on our theme colors.
    </CodeBlock>
    <CodeBlock title="Border Radius" code={BordersRadius} mdxType="CodeBlock">
  Add classes to an element to easily round its corners.
    </CodeBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      